import React, { FC, useState } from "react";
import { Flex, Box, Text, SystemProps } from "flicket-ui";
import styled from "styled-components";

const Nav = styled(Box).attrs({ as: "nav" })`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: ${(p) => p.theme.colors.N200};
  }
`;

const Ul = styled(Flex).attrs({
  as: "ul",
  position: "relative",
  justifyContent: "flex-start",
  width: 1,
})<{ index: number; tabWidth: string }>`
  list-style: none;
  flex-wrap: wrap;
`;

const Li = styled(Text).attrs({
  as: "li",
  mr: 5,
  pt: 1,
  pb: "5/4",
  lineHeight: "100%",
})<{ active?: boolean; tabWidth: string; activeColor?: string }>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  user-select: none;
  cursor: pointer;

  color: ${(p) =>
    p.active ? p.theme.colors[p.activeColor || "N800"] : p.theme.colors.N500};
  position: relative;

  &:after {
    content: "";

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    height: 2px;
    width: 100%;

    background: linear-gradient(
      to left,
      ${(p) => p.theme.colors.P300},
      ${(p) => p.theme.colors.P300}
    );
    background-size: ${(p) => (p.active ? "100% 100%" : "0% 100%")};
    background-position: 50% 50%;
    background-repeat: no-repeat;

    transition: all 0.2s ease-in-out;
  }
`;

interface TabItem {
  label: React.ReactNode;
  key: string;
}

interface Props {
  value?: number | string;
  options: (string | TabItem)[];
  onChange: (index: string | number, tabKey?: string) => void;
  initialIndex?: number;
  activeColor?: string;
  size?: string;
}

function isTabItemType(item: string | TabItem): item is TabItem {
  return typeof item !== "string";
}

export const Tabs: FC<Props & SystemProps> = ({
  value: controlledValue,
  onChange,
  options,
  initialIndex,
  activeColor,
  size = "M",
  ...props
}) => {
  const [uncontrolledIndex, setUncontrolledIndex] = useState(initialIndex || 0);

  const isControlled = typeof controlledValue !== "undefined";

  let controlledIndex: number | undefined;
  if (isControlled) {
    // Index value
    if (
      typeof controlledValue === "number" &&
      Number.isInteger(controlledValue)
    ) {
      controlledIndex = controlledValue;
    }
    // Key value
    else {
      controlledIndex = options.findIndex((item) =>
        typeof item === "string"
          ? item === controlledValue
          : item.key === controlledValue
      );
    }
  }

  const tabIndex = isControlled ? controlledIndex : uncontrolledIndex;

  const tabWidth = `${100 / options.length}%`;

  return (
    <Nav width={1}>
      <Box width={1} {...props}>
        <Ul index={tabIndex} tabWidth={tabWidth}>
          {options.map((item, index) => {
            const isTabItem = isTabItemType(item);
            const tabKey = isTabItem ? item.key : item;
            const elementKey = isTabItem ? item.key : `${item}-${index}`;
            const label = isTabItem ? item.label : item;

            return (
              <Li
                active={tabIndex === index}
                activeColor={activeColor}
                tabWidth={tabWidth}
                onClick={() => {
                  onChange(index, tabKey);
                  setUncontrolledIndex(index);
                }}
                py="6/4"
                key={elementKey}
                variant={`extraBold.${size}` as any}
              >
                {label}
              </Li>
            );
          })}
        </Ul>
      </Box>
    </Nav>
  );
};
