import React, { FC } from "react";
import { theme } from "flicket-ui";
import { ThemeProvider } from "styled-components";

import { BaseProvider, UserProvider, FormStateProvider } from "~context";
import { AlertManagerProvider } from "~context/AlertManager";
import { CurrentOrganizationOutput } from "~graphql/sdk";
import { ReCaptchaProvider } from "~context/RecaptchaContext";
import { POSAuthProvider } from "~context/POSAuthContext";
import { ScreenSizeProvider } from "~context/ScreenSizeContext";

interface ProvidersProps {
  organization?: CurrentOrganizationOutput | undefined;
  setOrganization?: (org: any) => void;
}

export const Providers: FC<ProvidersProps> = ({
  children,
  setOrganization,
  organization,
}) => {
  const recaptchaKey = process.env.RECAPTCHA_SITE_KEY;

  return (
    <BaseProvider organization={organization} setOrganization={setOrganization}>
      <ThemeProvider theme={theme}>
        <ScreenSizeProvider>
          <AlertManagerProvider>
            <ReCaptchaProvider reCaptchaKey={recaptchaKey}>
              <UserProvider>
                <FormStateProvider>
                  <POSAuthProvider>{children}</POSAuthProvider>
                </FormStateProvider>
              </UserProvider>
            </ReCaptchaProvider>
          </AlertManagerProvider>
        </ScreenSizeProvider>
      </ThemeProvider>
    </BaseProvider>
  );
};
